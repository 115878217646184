import { useFetchCandidateLastContractEndDate } from 'src/Hooks/Candidate/useFetchCandidateLastContractEndDate';
import { Props } from './RepositioningSkillMatching.types';
import moment from 'moment';
import { useFetchMissionsBySkillMatchingForRepositioning } from 'src/Hooks/Missions/useFetchMissionsBySkillMatchingForRepositioning';
import { useEffect, useMemo, useState } from 'react';
import useFetchCGC from 'src/Hooks/Agencies/useFetchCGC';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { getRepositioningMissionsSortBy, getMissionsSortOrder } from 'src/Redux/Missions/Selectors';
import { useInView } from 'react-intersection-observer';
import { DBCDIIStatus, Mission, SkillMatchingMission } from 'src/Services/API';
import { RepositioningMissionsSortBy, SortOrder } from 'src/Redux/Missions/Types';
import styles from './RepositioningSkillMatching.module.scss';
import { LoadingCard } from 'src/Components/Card';
import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import RepositioningCard from '../RepositioningCard';
import { illusReposi } from 'src/Assets_DEPRECATED';
import MissionDetail from 'src/Routes/Activity/Missions/MissionDetail';
import CGCModal from '../CGCModal';
import CGCMissionPropositionModal from '../CGCMissionPropositionModal';
import { getStatusStyle } from 'src/Services/Missions';
import classNames from 'classnames';
const RepositioningSkillMatching = ({
  candidateId,
  candidateData,
  statut,
  selectedDepartments,
  selectedQualifications,
}: Props) => {
  const { data: candidateLastContractEndDate } =
    useFetchCandidateLastContractEndDate(candidateId) ?? moment();

  const { inView, ref } = useInView();

  const sortBy = useSelector(getRepositioningMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);
  const currentAgency = useSelector(getCurrentAgency);
  const cgcQuery = useFetchCGC();

  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [missionToDisplay, setMissionToDisplay] = useState<string>();
  const {
    data: missionsPages,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useFetchMissionsBySkillMatchingForRepositioning({
    mainQualifications: selectedQualifications,
    departmentIds: selectedDepartments.map(item => item.id ?? ''),
    startDate: moment(candidateLastContractEndDate) ?? moment(),
    endDate: moment(candidateLastContractEndDate ?? '').add(6, 'months'),
  });
  const missions: SkillMatchingMission[] = (missionsPages?.pages.map(page => page!) ?? [])
    .flat(1)
    .filter(mission => moment(mission.endDate).isAfter(moment()) || mission.endDate === undefined);

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const sortedMissions = useMemo(() => {
    switch (sortBy) {
      case RepositioningMissionsSortBy.DISTANCE:
        return (missions ?? []).sort((a, b) => {
          return sortOrder === SortOrder.ASC
            ? (a.distanceToTT ?? Math.pow(10, 4)) > (b.distanceToTT ?? Math.pow(10, 4))
              ? 1
              : -1
            : (a.distanceToTT ?? -1) < (b.distanceToTT ?? -1)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.NB_POSITIONS:
        return (missions ?? []).sort((a, b) => {
          return sortOrder === SortOrder.ASC
            ? (a.numberPositions ?? 0) > (b.numberPositions ?? 0)
              ? 1
              : -1
            : (a.numberPositions ?? 0) < (b.numberPositions ?? 0)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.START_DATE:
        const missionsWithStartDate = missions?.filter(mission => mission.startDate !== undefined);
        return [
          ...(missionsWithStartDate ?? []).sort((a, b) => {
            return sortOrder === SortOrder.ASC
              ? moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
          ...(missions?.filter(mission => mission.startDate === undefined) ?? []),
        ];
      case RepositioningMissionsSortBy.STATUT:
        return [
          ...(missions ?? []).sort((a, b) => {
            const aSalesPhase =
              (a.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;
            const bSalesPhase =
              (b.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;

            return sortOrder === SortOrder.ASC
              ? aSalesPhase > bSalesPhase || moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : aSalesPhase < bSalesPhase || moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
        ];
    }
  }, [sortBy, sortOrder, missions]);

  useEffect(() => {
    if (
      sortedMissions !== undefined &&
      sortedMissions.length > 0 &&
      missionToDisplay === undefined
    ) {
      setMissionToDisplay(sortedMissions[0].missionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMissions]);

  return (
    <>
      {isLoading && (
        <div className={styles.loadingContainer}>
          {Array.from({ length: 10 }).map((_, idx) => (
            <div className={styles.loadingCard} key={`${idx} missionCard`}>
              <LoadingCard />
            </div>
          ))}
        </div>
      )}
      {!isLoading && sortedMissions.length > 0 && (
        <>
          {
            <div className={styles.header}>
              <div className={selectedMissions.length === 0 ? styles.checkBox : ''}>
                <Checkbox
                  checked={
                    sortedMissions.length === selectedMissions.length &&
                    selectedMissions.length !== 0
                  }
                  halfChecked={selectedMissions.length > 0}
                  onClick={() => {
                    selectedMissions.length === sortedMissions.length
                      ? setSelectedMissions([])
                      : setSelectedMissions(sortedMissions);
                  }}
                />
              </div>
              <CGCMissionPropositionModal
                candidateData={candidateData}
                selectedMissions={selectedMissions}
                statut={statut}
              />
            </div>
          }
          <div className={styles.container}>
            <div className={styles.leftPartContainer}>
              <div className={styles.missionsContainer}>
                {sortedMissions.map(mission => {
                  const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
                  const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
                  return (
                    <RepositioningCard
                      key={mission.missionId}
                      companyName={mission.company?.name ?? ''}
                      serviceName={mission.service?.name}
                      startDate={mission.startDate}
                      agencyId={mission.agencyId}
                      status={mission.salesPhase?.label}
                      isChecked={isSelected}
                      isSelected={missionToDisplay === mission.missionId}
                      qualificationName={mission.qualification?.label ?? ''}
                      onClick={() => setMissionToDisplay(mission.missionId)}
                      onCheckBoxClick={() => {
                        isSelected
                          ? setSelectedMissions(
                              selectedMissions.filter(
                                missionItem => mission.missionId !== missionItem.missionId
                              )
                            )
                          : setSelectedMissions([...selectedMissions, mission]);
                      }}
                      flag={{
                        count: mission.distanceToTT,
                        className:
                          mission.distanceToTT !== undefined
                            ? mission.distanceToTT <= 50
                              ? styles.kmFlagBelow
                              : styles.kmFlagAbove
                            : styles.kmFlagNone,
                        label: 'km',
                      }}
                      badgeClassName={classNames(
                        styles.badge,
                        getStatusStyle(mission.salesPhase?.id)
                      )}
                      isSkillMatching={true}
                      skillMatchingMatchScore={Math.round(
                        parseFloat(mission.matchScore ?? '0') * 100
                      )}
                    />
                  );
                })}

                <div ref={ref} />
              </div>
            </div>
            {selectedMissions.length >= 2 ? (
              <div className={styles.detailContainerMore}>
                <div className={styles.detailContainerTitle}>Sélection</div>
                <div className={styles.detailContainerContent}>
                  {selectedMissions.length} commandes sélectionnées
                </div>
                <img
                  src={illusReposi}
                  alt="repositionnement-illus"
                  className={styles.illusRepositioning}
                />
              </div>
            ) : (
              <div className={styles.detailContainer}>
                <MissionDetail
                  missionId={missionToDisplay}
                  candidateId={candidateData.id}
                  isSkillMatching={true}
                  SkillMatchingMission={
                    missions.filter(mission => mission.missionId === missionToDisplay)[0]
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
      {!isLoading && !sortedMissions.length && (
        <>
          <div>
            {!cgcQuery.data?.map(cgc => cgc.id).includes(currentAgency ?? '') &&
              candidateData.cdiiStatus !== DBCDIIStatus.processing &&
              candidateData.cdiiStatus !== DBCDIIStatus.suggested && (
                <CGCModal
                  candidateId={candidateData.id ?? ''}
                  candidateFirstName={candidateData.firstName ?? ''}
                  candidateLastName={candidateData.name ?? ''}
                  statut={candidateData.cdiiStatus?.toString() ?? ''}
                />
              )}
          </div>
          <p className={styles.noMission}>
            Aucune commande trouvées pour les compétences de {candidateData.name}{' '}
            {candidateData.firstName}.
          </p>
        </>
      )}
    </>
  );
};

export default RepositioningSkillMatching;
