import { PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CDICandidatesActions } from 'src/Redux/CDICandidates/Slice';
import { CDISortBy, SortOrder } from 'src/Redux/CDICandidates/Types';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import {
  sortingChoicesCDII,
  sortingChoicesMission,
  sortOrderChoices,
} from '../FiltersMenu.component';
import FiltersValidateButton from '../FiltersValidateButton';
import styles from './FiltersMenuContent.module.scss';
import { Props } from './FiltersMenuContent.types';

const FiltersMenuContent = ({
  setLocalSortOrder,
  setLocalSortBy,
  localSortOrder,
  localSortBy,
  isRepositioning,
}: Props) => {
  const dispatch = useDispatch();
  const onSortByChange = useCallback(
    (sortBy: CDISortBy | RepositioningMissionsSortBy) => {
      dispatch(
        isRepositioning
          ? missionsActions.setRepositioningMissionsSortBy(sortBy as RepositioningMissionsSortBy)
          : CDICandidatesActions.setCDISortBy(sortBy as CDISortBy)
      );
    },
    [dispatch, isRepositioning]
  );
  const onSortOrderChange = useCallback(
    (sortOrder: SortOrder) => {
      dispatch(
        isRepositioning
          ? missionsActions.setMissionSortOrder(sortOrder)
          : CDICandidatesActions.setCDISortOrder(sortOrder)
      );
    },
    [dispatch, isRepositioning]
  );
  return (
    <>
      {sortOrderChoices.map(dpm => {
        return (
          <PopupMenu.Item
            key={dpm.name}
            icon={dpm.icon}
            text={dpm.name}
            onClick={() => {
              setLocalSortOrder(dpm.mode);
            }}
            id={dpm.name}
            keepOpenOnClick
            rightIcon={dpm.mode === localSortOrder && <Check className={styles.checkIcon} />}
            className={classnames({ [styles.selectedPopupItem]: dpm.mode === localSortOrder })}
          />
        );
      })}
      <>
        <div className={styles.title}>trier par</div>
        {(isRepositioning ? sortingChoicesMission : sortingChoicesCDII).map(item => (
          <PopupMenu.Item
            key={item.name}
            text={item.name}
            onClick={() => {
              setLocalSortBy(item.mode);
            }}
            id={item.name}
            keepOpenOnClick
            rightIcon={item.mode === localSortBy && <Check className={styles.checkIcon} />}
            className={classnames({ [styles.selectedPopupItem]: item.mode === localSortBy })}
          />
        ))}
      </>
      <div className={styles.validateButtonContainer}>
        <FiltersValidateButton
          onClick={() => {
            onSortOrderChange(localSortOrder);
            onSortByChange(localSortBy);
          }}
        />
      </div>
    </>
  );
};

export default FiltersMenuContent;
