import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import { useMemo, useState } from 'react';
import { useFetchCompanies } from 'src/Hooks/Companies/useFetchCompanies';
import { toFetchStatus } from 'src/Services/Async';

import { FETCH_STATUS } from 'src/Redux/Types';
import { Props } from './SelectCompany.types';

const SelectCompany = ({
  selectedCompany,
  setSelectedCompany,
  canBeReset,
  agency,
  brandCode,
}: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchCompanies(keyword, agency, brandCode);
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  return (
    <ComboBox
      id="SelectCompany"
      disableClearable={!canBeReset}
      value={selectedCompany ?? null}
      onSearch={keyword => throttledSetKeyword(keyword)}
      searchResults={data ?? []}
      withSubLabel
      keyValueExtractor={({ companyId, companyName, siret, companyAddress, societyStatus }) => ({
        key: companyId ?? 'unknown',
        value: `${companyName} (${siret})`,
        subValue: `[${societyStatus}] | ${companyAddress?.postalCode} - ${companyAddress?.city}`,
      })}
      onChange={searchResult => setSelectedCompany(searchResult ?? undefined)}
      fetchStatus={
        keyword.length > 0
          ? toFetchStatus({ isError, isLoading, isSuccess })
          : FETCH_STATUS.FULFILLED
      }
      minLengthToSearch={1}
    />
  );
};

export default SelectCompany;
