export enum TALENTS_STATUS {
  IN_MISSION = 'en mission',
  INTERMISSION = 'intermission',
  SOON_AVAILABLE = 'bientôt dispo',
}

export enum TemporaryWorkersSource {
  agency = 'agency',
  regional = 'regional',
}
