import { EnumSearchMissionsBodySortBy } from 'src/Services/API';

export interface MissionsState {
  currentMissionId: string;
  canLoadMore: boolean;
  repositioningMissionsSortBy: RepositioningMissionsSortBy;
  sortOrder: SortOrder;
  missionsSortBy: EnumSearchMissionsBodySortBy;
}

export enum RepositioningMissionsSortBy {
  DISTANCE,
  NB_POSITIONS,
  START_DATE,
  STATUT,
}

export enum SortOrder {
  ASC,
  DESC,
}
