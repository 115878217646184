import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch, useSelector } from 'react-redux';

import { SortDown, SortUp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { getMissionsSortOrder, getMissionsSortBy } from 'src/Redux/Missions/Selectors';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { SortOrder } from 'src/Redux/Missions/Types';
import styles from './SortMissionsMenu.module.scss';
import { FiltersMenuProps } from './SortMissionsMenu.types';
import { EnumSearchMissionsBodySortBy } from 'src/Services/API';

export const MissionSortMenu = ({ trigger }: FiltersMenuProps) => {
  const sortBy = useSelector(getMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);

  const dispatch = useDispatch();

  return (
    <Menu
      trigger={trigger}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem
        onClick={() => dispatch(missionsActions.setMissionSortOrder(SortOrder.ASC))}
        selected={sortOrder === SortOrder.ASC}
        keepOpenOnClick
      >
        <SortUp />
        croissant
      </MenuItem>
      <MenuItem
        onClick={() => dispatch(missionsActions.setMissionSortOrder(SortOrder.DESC))}
        selected={sortOrder === SortOrder.DESC}
        keepOpenOnClick
      >
        <SortDown />
        décroissant
      </MenuItem>

      <div className={styles.title}>trier par</div>

      <MenuItem
        onClick={() =>
          dispatch(missionsActions.setMissionsSortBy(EnumSearchMissionsBodySortBy['date de début']))
        }
        selected={sortBy === EnumSearchMissionsBodySortBy['date de début']}
        keepOpenOnClick
      >
        par date de début
      </MenuItem>
      <MenuItem
        onClick={() =>
          dispatch(
            missionsActions.setMissionsSortBy(EnumSearchMissionsBodySortBy['nombre de postes'])
          )
        }
        selected={sortBy === EnumSearchMissionsBodySortBy['nombre de postes']}
        keepOpenOnClick
      >
        par nombre de postes à servir
      </MenuItem>
    </Menu>
  );
};
