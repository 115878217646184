import {
  Badge,
  CardClickable,
  CardContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { MissionsListProps } from './MissionsList.types';
import styles from './MissionsList.module.scss';
import moment from 'moment';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { getStatusLabel, getStatusStyle } from 'src/Services/Missions';
import { Circle } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

export const MissionsList = ({ missions }: MissionsListProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {missions.map(mission => (
        <CardClickable
          key={mission.missionId}
          color="white"
          onClick={() =>
            mission.missionId &&
            navigate(
              ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({ missionId: mission.missionId })
            )
          }
        >
          <CardContent
            overline={[mission.company?.name, mission.service?.name].filter(Boolean).join(' - ')}
            title={
              (
                <>
                  <Circle
                    variant="fill"
                    size="small"
                    color={mission.salesPhase?.id === 'AS' ? 'warning' : 'success'}
                  />
                  {mission.qualification?.label}
                </>
              ) as any
            }
            supportingLines={[
              [
                mission.startDate ? moment(mission.startDate).format('[débute le ]L') : undefined,
                mission.numberPositions
                  ? pluralFormat(mission.numberPositions, 'poste')
                  : undefined,
              ]
                .filter(Boolean)
                .join(' | '),
            ].filter(Boolean)}
            titleClassName={styles.title}
          />
          {mission.salesPhase?.id && (
            <Badge
              value={getStatusLabel(mission.salesPhase.id)}
              className={getStatusStyle(mission.salesPhase.id)}
            />
          )}
        </CardClickable>
      ))}
    </div>
  );
};
