import { Checkbox, FetchButton } from '@randstad-lean-mobile-factory/react-components-core';
import { WhiteCirclePlusEmpty } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { illusReposi } from 'src/Assets_DEPRECATED';
import { LoadingCard } from 'src/Components/Card';
import MissionDetail from 'src/Routes/Activity/Missions/MissionDetail';
import useFetchCGC from 'src/Hooks/Agencies/useFetchCGC';
import { useAddCandidateToMissions } from 'src/Hooks/Missions/useAddCandidateToMissions';
import { useFetchRepositioningMissions } from 'src/Hooks/Missions/useFetchRepositioningMissions';
import { SortOrder } from 'src/Redux/CDICandidates/Types';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { getRepositioningMissionsSortBy, getMissionsSortOrder } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { DBCDIIStatus, Mission } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import CGCMissionPropositionModal from '../CGCMissionPropositionModal';
import CGCModal from '../CGCModal';
import RepositioningCard from '../RepositioningCard';
import styles from './RepositioningMissions.module.scss';
import { Props } from './RepositioningMissions.types';
import { DASHBOARD_MENU } from '../../Cdii.types';

const RepositioningMissions = ({
  isCGC,
  statut,
  selectedQualifications,
  candidateData,
  selectedAgencies,
  filteredDistance,
  dashboardSection,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { inView, ref } = useInView();

  const sortBy = useSelector(getRepositioningMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const currentAgency = useSelector(getCurrentAgency);

  const addToMissionMutation = useAddCandidateToMissions();
  const addToMissionFetchStatus = toFetchStatus(addToMissionMutation);
  const cgcQuery = useFetchCGC();

  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [missionToDisplay, setMissionToDisplay] = useState<string>();
  const {
    data: missionsWithCount,
    fetchNextPage: fetchNextPageMission,
    isFetchingNextPage: isFetchingNextPageMission,
    isLoading: isLoadingMission,
  } = useFetchRepositioningMissions({
    qualificationIds:
      selectedQualifications.length > 0
        ? selectedQualifications?.map(qualification => qualification ?? '')
        : undefined,
    agencies: selectedAgencies,
    candidateId: candidateData.id ?? '',
  });
  const missions: Mission[] = (missionsWithCount?.pages.map(page => page.missions!) ?? [])
    .flat(1)
    .filter(mission => moment(mission.endDate).isAfter(moment()) || mission.endDate === undefined);

  useEffect(() => {
    if (inView && !isFetchingNextPageMission) {
      fetchNextPageMission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);
  const sortedMissions = useMemo(() => {
    const filteredMissions: Mission[] =
      filteredDistance >= 110
        ? missions
        : (missions ?? []).filter(
            (mission: any) => (mission.distanceToTT ?? 0) <= filteredDistance
          );
    switch (sortBy) {
      case RepositioningMissionsSortBy.DISTANCE:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === SortOrder.ASC
            ? (a.distanceToTT ?? Math.pow(10, 4)) > (b.distanceToTT ?? Math.pow(10, 4))
              ? 1
              : -1
            : (a.distanceToTT ?? -1) < (b.distanceToTT ?? -1)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.NB_POSITIONS:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === SortOrder.ASC
            ? (a.numberPositions ?? 0) > (b.numberPositions ?? 0)
              ? 1
              : -1
            : (a.numberPositions ?? 0) < (b.numberPositions ?? 0)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.START_DATE:
        const missionsWithStartDate = filteredMissions?.filter(
          mission => mission.startDate !== undefined
        );
        return [
          ...(missionsWithStartDate ?? []).sort((a, b) => {
            return sortOrder === SortOrder.ASC
              ? moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
          ...(filteredMissions?.filter(mission => mission.startDate === undefined) ?? []),
        ];
      case RepositioningMissionsSortBy.STATUT:
        return [
          ...(filteredMissions ?? []).sort((a, b) => {
            const aSalesPhase =
              (a.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;
            const bSalesPhase =
              (b.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;

            return sortOrder === SortOrder.ASC
              ? aSalesPhase > bSalesPhase || moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : aSalesPhase < bSalesPhase || moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
        ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, missionsWithCount, filteredDistance]);

  useEffect(() => {
    if (
      sortedMissions !== undefined &&
      sortedMissions.length > 0 &&
      missionToDisplay === undefined
    ) {
      setMissionToDisplay(sortedMissions[0].missionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMissions]);

  return (
    <>
      {isLoadingMission && (
        <div className={styles.loadingContainer}>
          {Array.from({ length: 10 }).map((_, idx) => (
            <div className={styles.loadingCard} key={`${idx} missionCard`}>
              <LoadingCard />
            </div>
          ))}
        </div>
      )}
      {!isLoadingMission && sortedMissions.length > 0 && (
        <>
          {
            <div className={styles.header}>
              <div className={selectedMissions.length === 0 ? styles.checkBox : ''}>
                <Checkbox
                  checked={
                    sortedMissions.length === selectedMissions.length &&
                    selectedMissions.length !== 0
                  }
                  halfChecked={selectedMissions.length > 0}
                  onClick={() => {
                    selectedMissions.length === sortedMissions.length
                      ? setSelectedMissions([])
                      : setSelectedMissions(sortedMissions);
                  }}
                />
              </div>
              {isCGC ? (
                <CGCMissionPropositionModal
                  candidateData={candidateData}
                  selectedMissions={selectedMissions}
                  statut={statut}
                />
              ) : (
                <FetchButton
                  secondary
                  disabled={selectedMissions.length === 0}
                  onClick={() =>
                    addToMissionMutation.mutate({
                      body: {
                        candidateId: candidateData.id ?? '',
                        missionIds: selectedMissions.map(mission => mission.missionId ?? ''),
                      },
                    })
                  }
                  className={styles.validateButton}
                  fetchStatus={addToMissionFetchStatus}
                  title={
                    selectedMissions.length > 1
                      ? 'rattacher aux commandes'
                      : 'rattacher à la commande'
                  }
                  onSuccess={() => {
                    ReactGA.event(ANALYTICS_EVENT.COUNT_ORDER_RATTACHEMENT, {
                      ...userInfo,
                      rattachementOrderCount: selectedMissions.length,
                      toggle: dashboardSection,
                    });

                    setSelectedMissions([]);
                    navigate(MODAL_ROUTES.REPOSITIONING_VALIDATION.route.buildPath({}), {
                      state: {
                        background: location,
                        modalParameters: {
                          selectedMissionNumber: selectedMissions.length,
                          missionId: selectedMissions[0].missionId,
                          missionAgencyId: selectedMissions[0].agencyId,
                        },
                      },
                    });
                  }}
                />
              )}
              {!cgcQuery.data?.map(cgc => cgc.id).includes(currentAgency ?? '') &&
                candidateData.cdiiStatus !== DBCDIIStatus.processing &&
                candidateData.cdiiStatus !== DBCDIIStatus.suggested && (
                  <CGCModal
                    candidateId={candidateData.id ?? ''}
                    candidateFirstName={candidateData.firstName ?? ''}
                    candidateLastName={candidateData.name ?? ''}
                    statut={candidateData.cdiiStatus?.toString() ?? ''}
                  />
                )}
            </div>
          }
          <div className={styles.container}>
            <div className={styles.leftPartContainer}>
              <div className={styles.missionsContainer}>
                {sortedMissions.map(mission => {
                  const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
                  const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
                  return (
                    <RepositioningCard
                      key={mission.missionId}
                      companyName={mission.company?.name ?? ''}
                      serviceName={mission.service?.name}
                      startDate={mission.startDate}
                      agencyId={mission.agencyId}
                      status={mission.salesPhase?.label}
                      isChecked={isSelected}
                      isSelected={missionToDisplay === mission.missionId}
                      qualificationName={mission.qualification?.label ?? ''}
                      onClick={() => setMissionToDisplay(mission.missionId)}
                      onCheckBoxClick={() => {
                        isSelected
                          ? setSelectedMissions(
                              selectedMissions.filter(
                                missionItem => mission.missionId !== missionItem.missionId
                              )
                            )
                          : setSelectedMissions([...selectedMissions, mission]);
                      }}
                      flag={{
                        count: mission.distanceToTT,
                        className:
                          mission.distanceToTT !== undefined
                            ? mission.distanceToTT <= 50
                              ? styles.kmFlagBelow
                              : styles.kmFlagAbove
                            : styles.kmFlagNone,
                        label: 'km',
                      }}
                    />
                  );
                })}

                <div ref={ref} />
              </div>
              {!isCGC && (
                <div
                  className={styles.addMissionButton}
                  onClick={() => {
                    dispatch(missionCreationActions.setCandidateId(candidateData.id));
                    const candidateId = candidateData.id;
                    if (candidateId)
                      navigate(
                        MODAL_ROUTES.REPOSITIONING_CDII_MISSION_CREATION.route.buildPath({
                          candidateId,
                        }),
                        {
                          state: {
                            background: location,
                            modalParameters: {
                              isFromMyRegion: dashboardSection === DASHBOARD_MENU.REGIONAL,
                            },
                          },
                        }
                      );
                  }}
                >
                  <WhiteCirclePlusEmpty />
                  <p className={styles.addMissionText}>créer une commande pour ce talent</p>
                </div>
              )}
            </div>
            {selectedMissions.length >= 2 ? (
              <div className={styles.detailContainerMore}>
                <div className={styles.detailContainerTitle}>Sélection</div>
                <div className={styles.detailContainerContent}>
                  {selectedMissions.length} commandes sélectionnées
                </div>
                <img
                  src={illusReposi}
                  alt="repositionnement-illus"
                  className={styles.illusRepositioning}
                />
              </div>
            ) : (
              <div className={styles.detailContainer}>
                <MissionDetail missionId={missionToDisplay} candidateId={candidateData.id} />
              </div>
            )}
          </div>
        </>
      )}
      {!isLoadingMission && !sortedMissions.length && (
        <>
          <div>
            {!cgcQuery.data?.map(cgc => cgc.id).includes(currentAgency ?? '') &&
              candidateData.cdiiStatus !== DBCDIIStatus.processing &&
              candidateData.cdiiStatus !== DBCDIIStatus.suggested && (
                <CGCModal
                  candidateId={candidateData.id ?? ''}
                  candidateFirstName={candidateData.firstName ?? ''}
                  candidateLastName={candidateData.name ?? ''}
                  statut={candidateData.cdiiStatus?.toString() ?? ''}
                />
              )}
          </div>
          <p className={styles.noMission}>
            Il n'y a aucune commande correspondant aux qualifications sélectionnées. Astuce:
            vérifier que les qualifications principales et secondaires sont à jour dans le dossier
            du talent
          </p>
        </>
      )}
    </>
  );
};

export default RepositioningMissions;
