import { CircleAdd } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Checkbox, FetchButton, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { illusReposi } from 'src/Assets_DEPRECATED';
import { DistanceFilter } from 'src/Components/DistanceFilter';
import { useAddCandidateToMissions } from 'src/Hooks/Missions/useAddCandidateToMissions';
import { useFetchRepositioningMissions } from 'src/Hooks/Missions/useFetchRepositioningMissions';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { getRepositioningMissionsSortBy, getMissionsSortOrder } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy, SortOrder } from 'src/Redux/Missions/Types';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import MissionDetail from 'src/Routes/Activity/Missions/MissionDetail';
import QualificationsPopupMenu from 'src/Routes/Talents/Candidates/RepositioningModal/QualificationsPopupMenu';
import FiltersMenu from 'src/Routes/Talents/Cdii/FiltersMenu';
import RepositioningCard from 'src/Routes/Talents/Cdii/Repositioning/RepositioningCard';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { Mission } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import styles from './RepositioningMissionsTalent.module.scss';
import { Props } from './RepositioningMissionsTalent.types';

const RepositioningMissionsTalent = ({
  talentId,
  selectedQualifications,
  selectedAgencies,
  filteredDistance,
  setFilteredDistance,
  distanceMissions,
  qualifications,
  setSelectedQualifications,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { inView, ref } = useInView();

  const sortBy = useSelector(getRepositioningMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const addToMissionMutation = useAddCandidateToMissions();
  const addToMissionFetchStatus = toFetchStatus(addToMissionMutation);

  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [missionToDisplay, setMissionToDisplay] = useState<string>();
  const {
    data: missionsWithCount,
    fetchNextPage: fetchNextPageMission,
    isFetchingNextPage: isFetchingNextPageMission,
    isLoading: isLoadingMission,
  } = useFetchRepositioningMissions({
    qualificationIds:
      selectedQualifications.length > 0
        ? selectedQualifications?.map(qualification => qualification ?? '')
        : undefined,
    agencies: selectedAgencies,
    candidateId: talentId,
  });
  const missions: Mission[] = (missionsWithCount?.pages.map(page => page.missions!) ?? [])
    .flat(1)
    .filter(mission => moment(mission.endDate).isAfter(moment()) || mission.endDate === undefined);

  useEffect(() => {
    if (inView && !isFetchingNextPageMission) {
      fetchNextPageMission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const sortedMissions = useMemo(() => {
    const filteredMissions: Mission[] =
      filteredDistance >= 110
        ? missions
        : (missions ?? []).filter(
            (mission: any) => (mission.distanceToTT ?? 0) <= filteredDistance
          );
    switch (sortBy) {
      case RepositioningMissionsSortBy.DISTANCE:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === SortOrder.ASC
            ? (a.distanceToTT ?? Math.pow(10, 4)) > (b.distanceToTT ?? Math.pow(10, 4))
              ? 1
              : -1
            : (a.distanceToTT ?? -1) < (b.distanceToTT ?? -1)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.NB_POSITIONS:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === SortOrder.ASC
            ? (a.numberPositions ?? 0) > (b.numberPositions ?? 0)
              ? 1
              : -1
            : (a.numberPositions ?? 0) < (b.numberPositions ?? 0)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.START_DATE:
        const missionsWithStartDate = filteredMissions?.filter(
          mission => mission.startDate !== undefined
        );
        return [
          ...(missionsWithStartDate ?? []).sort((a, b) => {
            return sortOrder === SortOrder.ASC
              ? moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
          ...(filteredMissions?.filter(mission => mission.startDate === undefined) ?? []),
        ];
      case RepositioningMissionsSortBy.STATUT:
        return [
          ...(filteredMissions ?? []).sort((a, b) => {
            const aSalesPhase =
              (a.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;
            const bSalesPhase =
              (b.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;

            return sortOrder === SortOrder.ASC
              ? aSalesPhase > bSalesPhase || moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : aSalesPhase < bSalesPhase || moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
        ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, missionsWithCount, filteredDistance]);

  useEffect(() => {
    if (
      sortedMissions !== undefined &&
      sortedMissions.length > 0 &&
      missionToDisplay === undefined
    ) {
      setMissionToDisplay(sortedMissions[0].missionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMissions]);

  return (
    <>
      {isLoadingMission && (
        <div className={styles.loadingScreen}>
          <Loader heightInRem={4} className={styles.loader} />
          <p>chargement des missions, cela peut prendre du temps</p>
        </div>
      )}
      {!isLoadingMission && (
        <>
          <div className={styles.missionsHeader}>
            <div className={styles.filterButton}>
              <DistanceFilter
                filteredDistance={filteredDistance}
                setFilteredDistance={setFilteredDistance}
                data={distanceMissions}
              />

              <QualificationsPopupMenu
                allQualifications={qualifications ?? []}
                setSelectedQualifications={setSelectedQualifications}
                selectedQualifications={selectedQualifications}
              />
              <FiltersMenu isRepositioning={true} />
            </div>
          </div>
          {sortedMissions.length > 0 && (
            <div className={styles.listContainer}>
              {
                <div className={styles.header}>
                  <div className={selectedMissions.length === 0 ? styles.checkBox : ''}>
                    <Checkbox
                      checked={
                        sortedMissions.length === selectedMissions.length &&
                        selectedMissions.length !== 0
                      }
                      halfChecked={selectedMissions.length > 0}
                      onClick={() => {
                        selectedMissions.length === sortedMissions.length
                          ? setSelectedMissions([])
                          : setSelectedMissions(sortedMissions);
                      }}
                    />
                  </div>

                  <FetchButton
                    disabled={selectedMissions.length === 0}
                    onClick={() =>
                      addToMissionMutation.mutate({
                        body: {
                          candidateId: talentId,
                          missionIds: selectedMissions.map(mission => mission.missionId ?? ''),
                          isRepositioningTalent: true,
                        },
                      })
                    }
                    className={styles.validateButton}
                    fetchStatus={addToMissionFetchStatus}
                    title={
                      selectedMissions.length > 1
                        ? 'rattacher aux commandes'
                        : 'rattacher à la commande'
                    }
                    onSuccess={() => {
                      ReactGA.event(ANALYTICS_EVENT.COUNT_ORDER_RATTACHEMENT, {
                        ...userInfo,
                        rattachementOrderCount: selectedMissions.length,
                      });
                      setSelectedMissions([]);
                      navigate('/repositioningValidation', {
                        state: {
                          background: location,
                          modalParameters: {
                            selectedMissionNumber: selectedMissions.length,
                            missionId: selectedMissions[0].missionId,
                            missionAgencyId: selectedMissions[0].agencyId,
                            isRepositioningTalent: true,
                          },
                        },
                      });
                    }}
                  />
                </div>
              }
              <div className={styles.container}>
                <div className={styles.leftPartContainer}>
                  <div className={styles.missionsContainer}>
                    {sortedMissions.map(mission => {
                      const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
                      const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
                      return (
                        <RepositioningCard
                          key={mission.missionId}
                          companyName={mission.company?.name ?? ''}
                          serviceName={mission.service?.name}
                          startDate={mission.startDate}
                          agencyId={mission.agencyId}
                          status={mission.salesPhase?.label}
                          isChecked={isSelected}
                          isSelected={missionToDisplay === mission.missionId}
                          qualificationName={mission.qualification?.label ?? ''}
                          onClick={() => setMissionToDisplay(mission.missionId)}
                          onCheckBoxClick={() => {
                            isSelected
                              ? setSelectedMissions(
                                  selectedMissions.filter(
                                    missionItem => mission.missionId !== missionItem.missionId
                                  )
                                )
                              : setSelectedMissions([...selectedMissions, mission]);
                          }}
                          flag={{
                            count: mission.distanceToTT,
                            className:
                              mission.distanceToTT !== undefined
                                ? mission.distanceToTT <= 60
                                  ? styles.kmFlagBelow
                                  : styles.kmFlagAbove
                                : styles.kmFlagNone,
                            label: 'km',
                          }}
                        />
                      );
                    })}

                    <div ref={ref} />
                  </div>

                  <div
                    className={styles.addMissionButton}
                    onClick={() => {
                      dispatch(missionCreationActions.setCandidateId(talentId));
                      navigate(
                        MODAL_ROUTES.REPOSITIONING_TALENT_MISSION_CREATION.route.buildPath({
                          talentId,
                        }),
                        {
                          state: {
                            background: location,
                            modalParameters: { isTalent: true },
                          },
                        }
                      );
                    }}
                  >
                    <CircleAdd variant="line" className={styles.circleAdd} />
                    <p className={styles.addMissionText}>commande pour ce talent</p>
                  </div>
                </div>
                {selectedMissions.length >= 2 ? (
                  <div className={styles.detailContainerMore}>
                    <div className={styles.detailContainerTitle}>Sélection</div>
                    <div className={styles.detailContainerContent}>
                      {selectedMissions.length} commandes sélectionnées
                    </div>
                    <img
                      src={illusReposi}
                      alt="repositionnement-illus"
                      className={styles.illusRepositioning}
                    />
                  </div>
                ) : (
                  <div className={styles.detailContainer}>
                    <MissionDetail
                      isRepositioningTalent
                      missionId={missionToDisplay}
                      candidateId={talentId}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!isLoadingMission && !sortedMissions.length && (
        <p className={styles.noMission}>
          Il n'y a aucune commande correspondant aux qualifications sélectionnées. Astuce: vérifier
          que les qualifications principales et secondaires sont à jour dans le dossier du talent
        </p>
      )}
    </>
  );
};

export default RepositioningMissionsTalent;
